<template>
    <div>
        <the-header/>
        <div id="page">
            <activite titre="Exercice" description="Ceci est un exe..." note="12"/>
        </div>
    </div>
</template>

<script>
import TheHeader from '../../components/Eleve/TheHeader.vue'
import Activite from '../../components/Eleve/activite/Activite.vue'

    export default {
        components: {
            TheHeader,
            Activite
        }
    }
</script>

<style scoped>
#page{
    background-color: var(--theme-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 80px);
}
</style>