import Vue from 'vue';
import VueRouter from 'vue-router';

import Accueil from './views/Accueil.vue';
import Connexion from './views/Connexion.vue';


import AccueilEleve from './views/eleve/AccueilEleve.vue';
import ActiviteEleve from './views/eleve/ActiviteEleve.vue';
import TravailEleve from './views/eleve/TravailEleve.vue';
import NoteEleve from './views/eleve/Notes.vue';


import AccueilProf from './views/prof/AccueilProf.vue';
import ActiviteProf from './views/prof/activite/ActiviteProf.vue';
import AjouterActivite from './views/prof/activite/AjouterActivite.vue';
import ModifierActivite from './views/prof/activite/ModifierActivite.vue';

import GestionProf from './views/prof/gestion/Gestion.vue';
import CreationComptes from './views/prof/gestion/CreationComptes.vue';
import VoirLogins from './views/prof/gestion/VoirLogins.vue';
import VoirNotes from './views/prof/gestion/VoirNotes.vue';




Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { 
            path: '/',
            component: Accueil
        },
        {
            path: '/Connexion',
            component: Connexion
        },
        {
            path: '/Eleve/',
            component: AccueilEleve
        },
        {
            path: '/Eleve/Activite',
            component: ActiviteEleve,
            props: true
        },
        {
            path: '/Eleve/Travail',
            component: TravailEleve,
            props: true
        },
        {
            path: '/Eleve/Notes',
            component: NoteEleve,
            props: true
        },
        {
            path: '/Professeur/',
            component: AccueilProf
        },
        {
            path: '/Professeur/Activite',
            component: ActiviteProf,
            props: true
        },
        {
            path: '/Professeur/Activite/Ajouter',
            component: AjouterActivite,
            props: true
        },
        {
            path: '/Professeur/Activite/Modifier',
            component: ModifierActivite
        },
        {
            path: '/Professeur/Gestion',
            component: GestionProf
        },
        {
            path: '/Professeur/Gestion/VoirLogins',
            component: VoirLogins
        },
        {
            path: '/Professeur/Gestion/CreationComptes',
            component: CreationComptes
        },
        {
            path: '/Professeur/Gestion/VoirNotes',
            component: VoirNotes
        }
        
    ]
})

export default router;