<template>
    <form id="page" @submit.prevent=submitForm novalidate>
        <table class="conteneur">
            <tr class="titre">
                <th><img src="../assets/Connexion.svg" alt=""></th>
            </tr>

            <tr>
                <td class="labels"><label for="identifiant">Nom d'utilisateur</label></td>
            </tr>
            <tr>
                <td><input class="textes" type="text" id="identifiant" v-model="form.NomUtilisateur"></td>
            </tr>

            <tr>
                <td class="labels"><label for="MDP">Mot de passe</label></td>
            </tr>
            <tr>
                <td><input class="textes" type="password" id="MDP" v-model="form.MotDePasse"></td>
            </tr>

            <tr>
                <td><router-link :to="redirection"><input type="submit" id="bouton" value="Login" @click="submitForm()"></router-link></td>
            </tr>

        </table>
        
    </form>
    
</template>

<script>

    export default {
        data(){
            return{
                form: {
                    NomUtilisateur: '',
                    MotDePasse: ''
                },
                redirection: '/',
                datas: '',
                correspondance: 0,
                compteur: 0
            }
            
        },
        methods:{
            submitForm() {
                // const identifiant = {
                //     1: "eleve",
                //     2: "prof"
                // }

                // this.$http.post('https://essai.saint-gab.ovh/Api.php',{action:'RecupererInfosConnexion'}).then(function(response){this.datas = response.data;});

                // console.log(this.datas.length)

                // while(this.compteur < this.datas.length){
                //     if((this.datas.login == this.NomUtilisateur) && (this.datas.password == this.MotDePasse)){
                //         this.compteur++;
                //     }
                // }
                // if(this.compteur == 1){
                //     this.redirection = '/Eleve'
                // }
                
                switch(this.form.NomUtilisateur){
                    case "eleve":
                        console.log("Bonjour eleve");
                        this.redirection = '/Eleve';

                        break;
                    case "prof":
                        console.log("Bonjour prof")
                        this.redirection = '/Professeur';

                        break;
                    default:
                        console.log("identifiant ou mot de passe incorrect");
                }
            }
        }
    }
</script>

<style scoped>

#page{
    min-height: 100vh;
    width: 100%;
    background-color: var(--theme-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.conteneur{
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 58%;
    padding: 3% 2% 2% 2%;
    font-family:var(--font);
}

.conteneur img{
    width: 25%;
}

.textes{
    height: 70px;
    width: 100%;
    background-color: #F3F3F3;
    border: solid 1px;
    padding-left: 2%;
    font-size: 1.2em;
}
.labels{
    font-size: 1.2em;
    font-weight:500;
    padding: 1% 0 2% 0;
}

td{
    padding-bottom: 10px;
}

th{
    padding-bottom: 5%;
}

#bouton{
    height: 70px;
    width: 100%;
    background-color: var(--theme-primary);
    border: none;
    margin-top: 2%;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 2.5em;
    font-family: var(--font);
    font-weight: 100;
}



.slide-up-enter-active{
  animation: slide-up-in 1.5s;
}


</style>