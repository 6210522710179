<template>
    <div>
        <the-header/>
        <div id="page">
             <h2>Notes de ELEVE</h2>
            <p class="affichageMoyenne"> Moyenne: 11/20</p>        
        
            <div class="espace"/>
            <table class="notes">
                <tr class="titre">
                    <th>Activité</th>
                    <th>Note</th>
                </tr>
                <tr class="listeNote">
                    <td class="affichage">Activité 1</td>
                    <td class="affichage">11</td>
                </tr>
            </table>

            


        </div>
    </div>
</template>

<script>
import TheHeader from '../../components/Eleve/TheHeader.vue'
    export default {
        components: {
            TheHeader
        }
    }
</script>

<style scoped>
#page{
    background-color: var(--theme-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 80px);
}
.notes{
    width: 50rem;
    background-color: #4D908E;
    border-spacing: 0;
    
}

.listeNote{
    height: 2rem;
}

.affichage{
    border: solid #4D908E;
    background-color: #FFF;
    padding: 0;
}

.affichageMoyenne{
    border: solid #4D908E;
    background-color: #FFF;
    padding: 0 0 0 4rem;
    width: 15rem;
}

.titre{
    background-color: #4D908E;
    color: #FFF;
    font-family: var(--font);
    font-size: 1.5em;
    font-weight: 100;
}

.espace{
    height: 10rem;
}



</style>