<template>
  <div class="vueprof">
    <the-header></the-header>
    <div class="page">
        <h2>Bonjour prof !</h2>
        <img src="../../assets/teacher.svg" class="laMadame">
        <les-boutons></les-boutons>
    </div>
  </div>
</template>

<script>
import TheHeader from '../../components/prof/TheHeader.vue'
import LesBoutons from '../../components/prof/accueil/LesBoutons.vue'

export default {
    components: {
        TheHeader,
        LesBoutons
    }
}
</script>

<style>
.vueprof{
    background-color: #43AA8B;
    min-height: 100vh;
    
}

.page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 80px);
}

.laMadame{
    width: 25%;
    margin: 5rem 0 4rem 0;
}

h2{
    color: #fff;
    font-family: "RobotoSlab" ,Georgia, serif;
    font-size: 2em;
    font-weight: 100;
}



@font-face {
    font-family: "RobotoSlab";
    src: url('../../../public/font/robotoSlab/RobotoSlab.ttf');
}
</style>