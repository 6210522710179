<template>
  <header>
    <router-link to="/Eleve" class="sdl"><img src="../../assets/Studio des langues.svg"></router-link>

      <img src="../../assets/user.png" class="user">
  </header>
</template>

<script>


export default {
    
}
</script>

<style>
    header{
        background-color: #4D908E;
        border-bottom: solid 1px #000;
        height: 10rem;
        font-family: 'Satisfy', Courier, monospace;
        font-weight: 100;
        color: #FFF;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .sdl{
        width: 2rem;
        cursor: pointer;
    }

    .user{
        width: 3rem;
        margin-left: 77%;
    }

    @font-face {
        font-family: Satisfy;
        src: url('../../../public/font/Satisfy/Satisfy-Regular.ttf');
    }
</style>