import { render, staticRenderFns } from "./ActiviteProf.vue?vue&type=template&id=4815ddd0&scoped=true&"
import script from "./ActiviteProf.vue?vue&type=script&lang=js&"
export * from "./ActiviteProf.vue?vue&type=script&lang=js&"
import style0 from "./ActiviteProf.vue?vue&type=style&index=0&id=4815ddd0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4815ddd0",
  null
  
)

export default component.exports