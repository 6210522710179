<template>
  <div>
    <the-header/>
    <div class="pageActivite">
      <div class="pageActivite-content">
        <div class="titreEtPlus">
          <div/>
          <h2>Liste des activités</h2>
          <router-link to="/Professeur/Activite/Ajouter">
            <img src="../../../assets/plus.png" class="plus"/>
          </router-link>
        </div>
        <div class="activites">
          <activite/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from '../../../components/prof/TheHeader.vue'
import Activite from '../../../components/prof/Activite/Activite.vue'


export default {
  components: {
    TheHeader,
    Activite
  }
}
</script>

<style scoped>
.pageActivite{
  background-color: var(--theme-primary);
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
}

h2{
  color: #fff;
  font-family: var(--font);
  font-size: 2em;
  font-weight: 100;
}

.plus{
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.pageActivite-content{
  width: 45rem;
  padding-top: 5rem;
}

.titreEtPlus{
  display: flex;
  justify-content: space-between;
}

.activites{
  padding-top: 3rem;
}

</style>