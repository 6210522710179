<template>
    <div class="activite">
        <img src="../../../assets/yes.png" class="image"/>
        <h2>Test</h2>
        <h2>Ceci est un test qui ...</h2>
        <img src="../../../assets/Menu.svg" class="menu"/>
    </div>
</template>

<script>

    export default {
        
    }
</script>

<style scoped>
.activite{
    border: solid 1px #000;
    border-radius: 20px;
    background-color: #577590;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 2rem 0 1rem;
    cursor: pointer;
}

h2{
    font-family: var(--font);
}

.image{
    
    height: 2.5rem;
    width: 2.5rem;
}

.menu{
    height: 2.5rem;
}


</style>