<template>
    <router-link to="/Eleve/Travail" class="activite">
        <div/>
        <h2>{{titre}}</h2>
        <h2>{{description}}</h2>
        <h3>{{note}}/20</h3>
    </router-link>
</template>

<script>

    export default {
        props: ["titre", "description", "note"]
    }
</script>

<style scoped>
.activite{
    border: solid 1px #000;
    border-radius: 20px;
    background-color: #577590;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 2rem 0 1rem;
    cursor: pointer;
    width: 40rem;
    text-decoration: none;
}

h2{
    font-family: var(--font);
}

h3{
    font-family: var(--font);
    color: #FFF;
    font-weight: 100;
    font-size: 1.5em;
}

.image{
    
    height: 2.5rem;
    width: 2.5rem;
}

.menu{
    height: 2.5rem;
}


</style>