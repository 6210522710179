<template>
    <div id="pageTravail">
        <div class="titre">
            <h2>Nom Activité</h2>
        </div>
        <the-script/>
        <div class="saisie">
            <input type="text" class="textes">
            <the-button value="Envoyer"/>
        </div>
        <div class="bottomButtons">
            <the-button value="Ressource"/>
            <the-button value="Sauvegarder"/>
            <the-button value="Rendre"/>
        </div>
        
    </div>
</template>

<script>
import TheScript from '../../components/Eleve/activite/PratiquerActivite/script.vue'
import TheButton from '../../components/Eleve/activite/PratiquerActivite/TheButton.vue'
    export default {
        components: {
            TheScript,
            TheButton
        }
    }
</script>

<style scoped>
#pageTravail{
    background-color: #577590;
    width: 100%;
    height: 100vh;
    
    padding: 2rem 4rem 2rem 4rem;
}

h2{
    font-family: var(--font);
}

.textes{
    height: 4rem;
    width: 68rem;
    background-color: #F3F3F3;
    border: solid 1px;
    padding-left: 2%;
    font-size: 1.2em;
}

.saisie{
    padding-top: 2rem;
    display: flex;
}

.bottomButtons{
     padding-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.titre{
    text-align: center;
    padding-bottom: 1rem;
}

</style>