<template>
    <div>
        <the-header/>
        <div id="page">
            <h2>Notes de CLASSE</h2>
            <div class="espace"/>
            <table>
                <tr class="titre">
                    <th>Identifiants</th>
                    <th style="width: 30rem">Notes</th>
                    <th>Moyenne</th>
                </tr>
                <tr>
                    <td>arichar1</td>
                    <td>20 2</td>
                    <td>11</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import TheHeader from '../../../components/prof/TheHeader.vue'
    export default {
        components: {
            TheHeader
        }
    }
</script>

<style scoped>
#page{
    background-color: var(--theme-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 80px);
}
table{
    width: 50rem;
    background-color: #4D908E;
    border-spacing: 0;
    
}

tr{
    height: 2rem;
}

td{
    border: solid #4D908E;
    background-color: #FFF;
    padding: 0;
}

.titre{
    background-color: #4D908E;
    color: #FFF;
    font-family: var(--font);
    font-size: 1.5em;
    font-weight: 100;
}

.espace{
    height: 10rem;
}

</style>