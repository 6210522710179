<template>
    <div>
        <the-header/>
        <div id="page">
            <table class="formulaire-container">
                <tr>
                    <th><h1>Création des comptes élève</h1></th>
                </tr>
                <tr>
                    <td><label for="">Classe</label></td>
                </tr>
                <tr>
                    <td><input type="text" class="textes"></td>
                </tr>
                <tr>
                    <td><label for="">Identifiants</label></td>
                </tr>
                <tr>
                    <td><textarea/></td>
                </tr>
    
                <tr>
                    <td><input type="submit" class="boutonValider" value="Valider"></td>
                </tr>
                
            </table>
        </div>
    </div>
</template>

<script>
import TheHeader from '../../../components/prof/TheHeader.vue'
    export default {
        components: {
            TheHeader
        }
    }
</script>

<style scoped>
#page{
    background-color: var(--theme-primary);
    /* height: calc(100vh - 80px); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem 0;
}

.formulaire-container{
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 58%;
    padding: 3% 2% 2% 2%;
    font-family:var(--font);
}

textarea{
    height: 140px;
    width: 100%;
    background-color: #F3F3F3;
    border: solid 1px;
    padding-left: 2%;
    font-size: 1.2em;
}

.textes{
    height: 70px;
    width: 100%;
    background-color: #F3F3F3;
    border: solid 1px;
    padding-left: 2%;
    font-size: 1.2em;
}
.labels{
    font-size: 1.2em;
    font-weight:500;
    padding: 1% 0 2% 0;
}

td{
    padding-bottom: 10px;
}

th{
    padding-bottom: 5%;
}

.boutonValider{
    height: 70px;
    width: 100%;
    background-color: var(--theme-primary);
    border: none;
    margin-top: 2%;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 2.5em;
    font-family: var(--font);
    font-weight: 100;
}

</style>