<template>
    <div id="button">
        <h2>{{value}}</h2>
    </div>
</template>

<script>
    export default {
        props: ["value", "direction"]
    }
</script>

<style scoped>
#button{
    background-color: #43AA8B;
    width: 20rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

</style>