<template>
    <div id="script">

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
#script{
    background-color: #FFF;
    height: 28rem;
    width: 100%;
}
</style>