<template>
<router-link :to="destination">
    <button class="bouton">
      <p>{{ text }}</p>
  </button>
</router-link>
  
</template>

<script>


export default {
    props: [
        'text',
        'destination'
    ]
}
</script>

<style>
    .bouton{
        background-color: #F9844A;
        border-radius: 15px;
        padding: 0.5rem 4rem;
        cursor: pointer;
        border: none;
    }

    .bouton p{
        font-family: "RobotoSlab" ,Georgia, serif;
        font-weight: 100;
        font-size: 1.9em;
    }
</style>