<template>
    <div>
        <the-header/>
        <div class="page">
            <h2>Que souhaitez-vous faire ?</h2>
            <img src="../../../assets/Question.svg" class="question">
            <triple-bouton/>
        </div>
    </div>
</template>

<script>
import TheHeader from '../../../components/prof/TheHeader.vue'
import TripleBouton from '../../../components/prof/Gestion/TripleBoutons.vue'

    export default {
        components: {
            TheHeader,
            TripleBouton
        }
    }
</script>

<style scoped>
.page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 80px);
    background-color: var(--theme-primary);
}

.question{
    width: 25%;
    margin: 5rem 0 4rem 0;
}

h2{
    color: #fff;
    font-family: "RobotoSlab" ,Georgia, serif;
    font-size: 2em;
    font-weight: 100;
}



</style>