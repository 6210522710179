<template>
  <div class="boutons">
      <simple-bouton text="Activités" destination="/Eleve/Activite"/>
      <simple-bouton text="Notes" destination="/Eleve/Notes"/>
  </div>
</template>

<script>
import SimpleBouton from '../../Bouton.vue'


export default {
    components: {
        SimpleBouton
    },
    
}
</script>

<style scoped>
    .boutons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 29rem;
    }


</style>