<template>
    <div class="accueil">
        <img class="hello" src="../assets/Accueil.svg">
        <router-link to="/Connexion"><simple-bouton text="Connexion" destination="/Connexion"></simple-bouton></router-link>
    </div>
</template>

<script>

import SimpleBouton from '../components/Bouton.vue'


export default {
        components: {
            SimpleBouton
        }
}
</script>


<style scoped>
.accueil{
    min-height: 100vh;
    width: 100%;
    background-color: #277DA1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hello {
    /* margin: 10% 0 8% 28%; */
    width: 40%;
    margin-bottom: 8rem;
}

.bouton{
    /* display: block;
    margin: 0 auto 0 auto; */
    width: 4rem;
    margin-top: 159px;
    cursor: pointer;
}

    
.slide-up-leave-active{
  position: absolute;
}

.slide-up-leave-to{
  animation: slide-up-out 1.5s;
}


</style>